import React, { useState, useEffect } from 'react';
import './styles.scss';

const Listing = () => {
	const [activeTab, setActiveTab] = useState('Список компаний');
	const [activeCompanyList, setActiveCompanyList] = useState(null);

	const tabs = [
		'Список компаний',
		'Облигации',
		'Акции',
		'Финансовая отчетность',
		'Требование к листингу',
		'Листинговые виртуальные активы',
	];

	const data = {
		'Список компаний': {
			categories: [
				{
					name: 'Листинговые',
					companies: [
						'ОАО Енвойс Вижион Диджитал Эксчейндж',
						'Фригат Люксембург LLC',
						'ОАО Коммерческий банк Кыргызстан',
						'ОАО Международный аэропорт Манас',
						'ОАО Кыргыз телеком',
						'ОАО Электрические станции',
						'ОАО Национальная электрическая сеть Кыргызстана',
						'ОАО ТНК Дастан',
						'ОАО Кантский Цементный завод',
						'ОАО Бишкексут',
						'ОАО Учкун',
						'ЗАО Банк Азии',
						'ЗАО Кыргызский Инвестиционно-Кредитный Банк',
						'ЗАО Микрофинансовая Компания ОКСУС',
						'ЗАО Микрофинансовая компания Элет-Капитал',
						'ОАО Дос - Кредобанк',
					],
				},
				{ name: 'Делистинг в ОАО ЕВДЭ', companies: ['ОАО Техсервис'] },
			],
		},
		Облигации: [
			'ОАО Коммерческий банк Кыргызстан',
			'ЗАО Банк Азии',
			'ЗАО Кыргызский Инвестиционно-Кредитный Банк',
			'ЗАО Микрофинансовая Компания ОКСУС',
			'ЗАО Микрофинансовая компания Элет-Капитал',
			'ОАО Дос - Кредобанк',
		],
		Акции: [
			'ОАО Коммерческий банк Кыргызстан',
			'ОАО Международный аэропорт Манас',
			'ОАО Кыргыз телеком',
			'ОАО Электрические станции',
			'ОАО Национальная электрическая сеть Кыргызстана',
			'ОАО Енвойс Вижион Диджитал Эксчейндж',
			'ОАО ТНК Дастан',
			'ОАО Кантский Цементный завод',
			'ОАО Бишкексут',
			'ОАО Учкун',
		],
		'Финансовая отчетность': [
			{
				id: 6,
				name: 'Аудированная годовая финансовая отчетность Фригат Люксембург LLC 2021г',
				url: 'https://drive.google.com/file/d/1MwDgNw4zrOZCEmVn_LWwDHtYCwPnZ5aK/view?usp=sharing',
			},
		],
		'Требование к листингу': [
			{
				id: 7,
				name: 'Листинговые требования',
				url: 'https://docs.google.com/document/d/1CmOKIaRUkHQLi751GYIHUAVGklWZ7Vii/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 8,
				name: 'Приложение №1 Договор листинга',
				url: 'https://docs.google.com/document/d/15UYmgdS3-QfSCgUFr4O7DPqPnzDWL5jK/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 9,
				name: 'Приложение №2 Заявка на листинг',
				url: 'https://docs.google.com/document/d/15GRSVMlOIp93YBfEpn1BGK1PUJFPylrx/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 10,
				name: 'Приложение №3 Перечень документов для листинга',
				url: 'https://docs.google.com/document/d/1HC18zwUp1mUZKRW8CRdLQTQLOI3v5tmu/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 11,
				name: 'Приложение №4 Перечень документов для листинга Оригинаторам',
				url: 'https://docs.google.com/document/d/1vsUGvND9sQeaTpl5uM9cDhAlj0d9NA1C/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 12,
				name: 'Приложение №6 Требования к Эмитенту для целей поддержания листинга',
				url: 'https://docs.google.com/document/d/1CQTYW-JFaiKFuEkXQkpwAbR6KlqqGxY5/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
			{
				id: 13,
				name: 'Приложение №7 Листинговый проспект',
				url: 'https://docs.google.com/document/d/1TBZ8IR0Lowg56Ci6xz6HuFN9omQptuEi/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			},
		],
		'Листинговые виртуальные активы': [
			'Tether (USDT)',
			'Bitcoin(BTC)',
			'Tron (TRX)',
			'Ethereum (ETH)',
		],
	};

	useEffect(() => {
		if (
			activeTab === 'Список компаний' &&
			data[activeTab] &&
			data[activeTab].categories.length > 0
		) {
			setActiveCompanyList(data[activeTab].categories[0].name);
		}
	}, [activeTab]);

	const handleCategoryClick = (categoryName) => {
		setActiveCompanyList(categoryName);
	};

	const renderContent = () => {
		if (activeTab === 'Список компаний') {
			return (
				<div className='listing__companies-container'>
					<div className='listing__categories'>
						{data[activeTab].categories.map((category) => (
							<button
								key={category.name}
								className={`listing__category-button ${
									activeCompanyList === category.name
										? 'listing__category-button--active'
										: ''
								}`}
								onClick={() => handleCategoryClick(category.name)}
							>
								{category.name}
							</button>
						))}
					</div>
					<div className='listing__company-list'>
						{activeCompanyList && (
							<>
								{data[activeTab].categories
									.find((category) => category.name === activeCompanyList)
									.companies.map((company, index) => (
										<div
											key={`${company}-${index}`}
											className='listing__company-item'
										>
											{company}
										</div>
									))}
							</>
						)}
					</div>
				</div>
			);
		} else if (
			activeTab === 'Финансовая отчетность' ||
			activeTab === 'Требование к листингу'
		) {
			return (
				<div className='listing__items'>
					{data[activeTab].map((item) => (
						<a
							key={item.id}
							href={item.url}
							target='_blank'
							rel='noopener noreferrer'
							className='listing__item listing__item--link'
						>
							{item.name}
						</a>
					))}
				</div>
			);
		} else {
			return (
				<div className='listing__items'>
					{data[activeTab].map((item) => (
						<div key={item} className='listing__item'>
							{item}
						</div>
					))}
				</div>
			);
		}
	};

	return (
		<div className='listing' id='listing'>
			<div className='container'>
				<h1 className='listing__title'>Листинг</h1>
				<div className='listing__tabs'>
					{tabs.map((tab) => (
						<button
							key={tab}
							className={`listing__tab ${
								activeTab === tab ? 'listing__tab--active' : ''
							}`}
							onClick={() => {
								setActiveTab(tab);
								if (tab !== 'Список компаний') {
									setActiveCompanyList(null);
								}
							}}
						>
							{tab}
						</button>
					))}
				</div>
				<div className='listing__info'>{renderContent()}</div>
			</div>
		</div>
	);
};

export default Listing;
