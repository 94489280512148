// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboard {
  max-width: 860px;
  margin: 0 auto 80px auto;
}
.onboard__title {
  text-align: center;
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
}
.onboard__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.onboard__item a {
  font-size: 15px;
  color: #f48020;
  text-decoration: none;
}
.onboard__item a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/documents/onboard/styles.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,wBAAA;AACD;AAAC;EACC,kBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;AAEF;AAAC;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;AACE;EACC,eAAA;EACA,cAAA;EACA,qBAAA;AACH;AACG;EACC,0BAAA;AACJ","sourcesContent":[".onboard {\n\tmax-width: 860px;\n\tmargin: 0 auto 80px auto;\n\t&__title {\n\t\ttext-align: center;\n\t\tcolor: #fff;\n\t\tfont-size: 24px;\n\t\tmargin-bottom: 20px;\n\t}\n\t&__list {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tgap: 15px;\n\t}\n\t&__item {\n\t\ta {\n\t\t\tfont-size: 15px;\n\t\t\tcolor: #f48020;\n\t\t\ttext-decoration: none;\n\n\t\t\t&:hover {\n\t\t\t\ttext-decoration: underline;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
