import React, { useState } from 'react';
import './styles.scss';
import Onboard from './onboard/Onboard';
import Members from './members/Members';

const Documents = () => {
	const [applications] = useState([
		{
			text: 'Договор присоединения',
			href: 'https://drive.google.com/file/d/1mdiO4hI5Dxypy_U-J5gM-M9L_6iNb3RO/view?usp=sharing',
			isPrimary: true,
		},
		{
			text: 'Заявка для юр. лица',
			href: 'https://docs.google.com/document/d/1acoFYOpanyZYz0X0OlkeRdYA7YZT75NH/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			isPrimary: false,
		},
		{
			text: 'Заявка для физ. лица',
			href: 'https://docs.google.com/document/d/150BAaj1nhd5VcV0DK3b_OUHA1dgmIwYQ/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
			isPrimary: false,
		},
	]);
	const [sections] = useState([
		{
			title: 'Фондовая биржа',
			id: 'stock',
			links: [
				{
					content: 'Правила Фондовой Биржи',
					href: 'https://drive.google.com/file/d/16C0Xv4ph81sGdhxi8tFnnCD-4o6VE34n/view?usp=sharing',
				},
			],
		},
		{
			title: 'Валютная биржа',
			id: 'currency',
			links: [
				{
					content: 'Правила Валютной Биржи',
					href: 'https://drive.google.com/file/d/1kbTAaynA711WFCZz0R8hM5bbvge81vkX/view?usp=sharing',
				},
			],
		},
		{
			title: 'Товарная биржа',
			id: 'commodity',
			links: [
				{
					content: 'Правила биржевой торговли',
					href: 'https://drive.google.com/file/d/1r-GP7oAtiuLvoMPoe6piXvUXNbXhLtcq/view?usp=sharing',
				},
				{
					content: 'Заявка на покупку и продажу биржевых товаров',
					href: 'https://docs.google.com/document/d/1PWzfCGGdZJXPHo3WSgGkvVM51Lpv1LwP/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
			],
		},
		{
			title: 'Криптовалютная биржа',
			id: 'crypto',
			links: [
				{
					content: 'Правила Криптовалютной Биржи',
					href: 'https://drive.google.com/file/d/1XF4FEwq0xtDuW9Py94ccJAK0oTERHE0x/view?usp=sharing',
				},
			],
		},
		{
			title: 'Листинг',
			id: 'listing',
			links: [
				{
					content: 'Правила Листинга',
					href: 'https://drive.google.com/file/d/1dLD4shhaVBazHlOiqPpFgRpYfVyZg5BM/view?usp=sharing',
				},
			],
		},
	]);
	const [activeTab, setActiveTab] = useState('stock');
	const handleTabChange = (id) => {
		setActiveTab(id);
	};

	return (
		<section className='documents'>
			<div className='container'>
				<h1 className='documents__title'>
					Документы ОАО «Енвойс Вижион Диджитал Эксчейндж»
				</h1>
				<p className='documents__description'>
					Для установления деловых отношений Вам необходимо ознакомиться с
					Договором Присоединения и заполнить заявку физического лица или
					юридического лица. <br />
					<br />
					Затем, вам необходимо заполнить анкету физического или юридического
					лица, либо партнера. <br />
					<br />А также предоставить список документов для онбординга. <br />
					<br /> Просим Вас ознакомиться с Правилами Биржи (фондовой, валютной,
					товарной или криптобиржи). <br />
					<br /> Заполненные документы просим высылать на{' '}
					<a
						target='_blank'
						href='https://mail.google.com/mail/u/0/?fs=1&to=onboard@envoys.vision&tf=cm'
						className='documents__email'
					>
						onboard@envoys.vision
					</a>
					. <br /> <br />В зависимости от того, какие инструменты Вам интересны,
					Вам будет выслан Договор и соответствующие заявки/поручения.
				</p>
				<div className='documents__buttons'>
					{applications.map((item, index) => (
						<a
							target='_blank'
							key={index}
							href={item.href}
							className={`documents__buttons__button ${
								item.isPrimary ? 'documents__buttons__button--primary' : ''
							}`}
						>
							{item.text}
						</a>
					))}
				</div>
				<div className='documents__tabs'>
					{sections.map((section) => (
						<button
							key={section.id}
							className={`documents__tabs__tab ${
								activeTab === section.id ? 'documents__tabs__tab--active' : ''
							}`}
							onClick={() => handleTabChange(section.id)}
						>
							{section.title}
						</button>
					))}
				</div>
				<div className='documents__content'>
					{sections
						.find((section) => section.id === activeTab)
						?.links.map((link, index) => (
							<a
								target='_blank'
								key={index}
								href={link.href}
								className='documents__rules-link'
							>
								{link.content}
							</a>
						))}
				</div>

				<Onboard />
				<Members />
			</div>
		</section>
	);
};

export default Documents;
