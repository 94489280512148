import React, { useEffect, useState } from 'react';
import './styles.scss';

import telegramIcon from '../../images/telegram-icon.svg';
import instagramIcon from '../../images/instagram-icon.svg';
import twitterIcon from '../../images/x-icon.svg';
import tiktokIcon from '../../images/tiktok-icon.svg';
import { Link, useLocation } from 'react-router-dom';

const socialLinks = [
	{
		name: 'Telegram',
		icon: telegramIcon,
		href: 'https://t.me/envoys_vision',
	},
	{
		name: 'Instagram',
		icon: instagramIcon,
		href: 'https://www.instagram.com/envoys.vision/',
	},
	{
		name: 'Twitter',
		icon: twitterIcon,
		href: 'https://twitter.com/envoysvisionde',
	},
	{
		name: 'TikTok',
		icon: tiktokIcon,
		href: 'https://www.tiktok.com/@envoys.vision',
	},
];

const footerSections = [
	{
		title: 'О нас',
		links: [
			{ text: 'О Представителях', url: 'https://envoys.vision/about' },
			{
				text: 'Финансовая отчетность',
				url: '/statement',
			},
			{
				text: 'Новости',
				url: '/exchange-news',
			},
			{
				text: 'Конфиденциальность',
				url: 'https://envoys.vision/info/confidentiality',
			},
			{
				text: 'Раскрытие рисков',
				url: 'https://envoys.vision/info/disclosure',
			},
			{
				text: 'Политика конфиденциальности',
				url: 'https://envoys.vision/info/privacy-policy',
			},
			{
				text: 'Положения и условия',
				url: 'https://envoys.vision/info/terms-and-conditions',
			},
			{
				text: 'Политика AML',
				url: 'https://envoys.vision/info/aml-policy',
			},
			{
				text: 'AB and AC Policy',
				url: '/assets/aml/Anti-Bribery_and_Anti-Corruption_Policy_EVDE.pdf',
			},
		],
	},
	{
		title: 'Поддержка',
		links: [
			{ text: 'Цена криптовалюты', url: 'https://envoys.vision/markets' },
			{ text: 'Документы', url: 'https://envoys.vision/documents' },
			{ text: 'Листинг', url: '/' },
		],
	},
	{
		title: 'Свяжитесь с нами',
		links: [{ text: 'Контакты', url: 'https://envoys.vision/contacts' }],
	},
];

const Footer = () => {
	const location = useLocation();
	const [prevLocation, setPrevLocation] = useState(null);

	useEffect(() => {
		if (prevLocation && location.pathname === prevLocation.pathname) {
			const hash = window.location.hash;
			if (hash) {
				const element = document.querySelector(hash);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				}
			} else {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		}
		setPrevLocation(location);
	}, [location, prevLocation]);

	return (
		<footer className='footer'>
			<div className='container'>
				<div className='footer__container'>
					<div className='footer__left'>
						<h3 className='footer__title'>Присоединяйтесь к сообществу</h3>
						<p className='footer__description'>
							Присоединяйтесь к нашему активному сообществу создателей и
							партнеров
						</p>
						<div className='footer__social'>
							{socialLinks.map((link, index) => (
								<a
									key={index}
									target='_blank'
									href={link.href}
									className='footer__social-link'
								>
									<img
										src={link.icon}
										alt={link.name}
										className='footer__social-icon'
									/>
								</a>
							))}
						</div>
					</div>
					<div className='footer__right'>
						{footerSections.map((section, index) => (
							<div key={index} className='footer__column'>
								<h4 className='footer__subtitle'>{section.title}</h4>
								<ul className='footer__list'>
									{section.links.map((link, linkIndex) => (
										<li key={linkIndex} className='footer__item'>
											{link.url.startsWith('http') ||
											link.url.startsWith('/assets') ? (
												<a
													href={link.url}
													target='_blank'
													rel='noopener noreferrer'
													className='footer__link'
												>
													{link.text}
												</a>
											) : (
												<Link to={link.url} className='footer__link'>
													{link.text}
												</Link>
											)}
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className='footer__bottom'>
				<p className='footer__copyright'>
					© 2024 Envoys Vision Digital Exchange. Все права защищены.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
