import React, { useState } from 'react';
import './styles.scss';

const Members = () => {
	const [activeTab, setActiveTab] = useState('members');
	const [activeLegislationTab, setActiveLegislationTab] = useState('rules');

	const tabs = [
		{ id: 'members', title: 'Участники' },
		{ id: 'legislation', title: 'Законодательство КР' },
		{ id: 'additional', title: 'Дополнительная информация' },
	];

	const legislationTabs = [
		{ id: 'rules', title: 'Правила' },
		{ id: 'laws', title: 'Законы' },
	];

	const members = [
		{ name: 'Royal Pure Gold', website: 'https://rpg.kg/' },
		{ name: 'Omega Capital', website: 'https://omegacapital.kg/' },
		{
			name: 'ATO - Finance',
			website: 'https://ato-finance.kg',
		},
		{ name: 'Asia Capital', website: 'https://asia-capital.kg/' },
		{ name: 'Prime Capital', website: 'https://primecap.kg/' },
		{ name: 'Asteri Capital', website: 'https://astericapital.kg/' },
	];

	const linksLaws = [
		{
			text: 'Закон об акционерных обществах',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/1188',
		},
		{
			text: 'Закон о рынке ценных бумаг',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202677',
		},
		{
			text: 'Налоговый кодекс кыргызской республики',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202445',
		},
		{
			text: 'Гражданский кодекс кыргызской республики',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/4',
		},
		{
			text: 'Гражданский кодекс кыргызской республики часть II',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/5?cl=ru-ru',
		},
		{
			text: 'Закон о хозяйственных товариществах и обществах',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/667',
		},
		{
			text: 'Закон о порядке проведения проверок субъектов предпринимательства',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202105',
		},
		{
			text: 'Закон о Национальном банке Кыргызской Республики, банках и банковской деятельности',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111486',
		},
		{
			text: 'Закон о статусе судебных исполнителей и об исполнительном производстве',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111522',
		},
		{
			text: 'Закон о государственной регистрации юридических лиц, филиалов (представительств)',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/202621',
		},
		{
			text: 'Закон о накопительных пенсионных фондах в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/205090',
		},
		{
			text: 'Закон об инвестировании средств для финансирования накопительной части пенсии по государственному социальному страхованию в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/203321',
		},
		{
			text: 'Закон об инвестициях в кыргызской республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/1190',
		},
		{
			text: 'Закон об инвестиционных фондах',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/255',
		},
		{
			text: 'Гражданский процессуальный кодекс',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111521',
		},
		{
			text: 'Кодекс кыргызской республики о нарушениях',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/111565/180?cl=ru-ru&mode=tekst',
		},
		{
			text: 'Закон о лицензионно-разрешительной системе в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/205058',
		},
	];

	const linksRegulations = [
		{
			text: 'Положение о порядке представления отчетности (информации) и раскрытии информации субъектами финансового рынка',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12634',
		},
		{
			text: 'Положение о требованиях, предъявляемых к деятельности фондовых бирж на рынке ценных бумаг',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92574?cl=ru-ru#p1',
		},
		{
			text: 'Постановление об утверждении минимальных требований к листингу ценных бумаг в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/97473',
		},
		{
			text: 'Положение о работе банков с ценными бумагами',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/302442?cl=ru-ru',
		},
		{
			text: 'Положение о классификации активов и соответствующих отчислениях в резерв на покрытие потенциальных потерь и убытков',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/31372?cl=ru-ru',
		},
		{
			text: 'Положение о Государственной службе регулирования и надзора за финансовым рынком при Правительстве Кыргызской Республики',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12873?cl=ru-ru',
		},
		{
			text: 'Постановление об утверждении Положения о профессиональной деятельности брокеров и дилеров на рынке ценных бумаг Кыргызской Республики',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92825',
		},
		{
			text: 'Постановление об утверждении Правил совершения сделок с ценными бумагами в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92870',
		},
		{
			text: 'Положение о нормативных показателях достаточности собственных средств, устанавливаемых для профессиональных участников рынка ценных бумаг',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/93100?cl=ru-ru',
		},
		{
			text: 'Положение о лицензировании отдельных видов деятельности',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/6849',
		},
		{
			text: 'Положение о ведении реестра держателей ценных бумаг в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/95234?cl=ru-ru',
		},
		{
			text: 'Положение о Едином государственном реестре ценных бумаг в Кыргызской Республике и порядке присвоения государственного регистрационного номера выпуску ценных бумаг',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92681?cl=ru-ru#p1',
		},
		{
			text: 'Постановление об утверждении Положения о порядке присвоения регистрационного номера государственным и муниципальным ценным бумагам и ведения реестра государственных и муниципальных ценных бумаг в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92228?cl=ru-ru#p0',
		},
		{
			text: 'Положение о порядке возврата владельцам ценных бумаг денежных средств (имущества), полученных эмитентом в счет оплаты ценных бумаг, выпуск которых признан несостоявшимся или недействительным',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92827?cl=ru-ru',
		},
		{
			text: 'Постановление об утверждении стандартов внутреннего учета операций с ценными бумагами в брокерских компаниях',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92680?ckwds=%25d1%2586%25d0%25b5%25d0%25bd%25d0%25bd%25d1%258b%25d1%2585%2B%25d0%25b1%25d1%2583%25d0%25bc%25d0%25b0%25d0%25b3',
		},
		{
			text: 'Положение о порядке выпуска, размещения, обращения и погашения муниципальных ценных бумаг в Кыргызской Республике',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/59799?cl=ru-ru',
		},
		{
			text: 'Положение о выпуске, размещении, обращении и погашении государственных ценных бумаг Кыргызской Республики',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/59481?cl=ru-ru',
		},
		{
			text: 'Положение о порядке представления отчетности (информации) и раскрытии информации субъектами финансового рынка',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/12634?cl=ru-ru',
		},
		{
			text: 'Положение о депозитарной деятельности',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/95126',
		},
		{
			text: 'Положение о порядке государственной регистрации выпуска акций, условий публичного предложения акций, проспекта и итогов выпуска акций',
			href: 'http://cbd.minjust.gov.kg/act/view/ru-ru/92638?cl=ru-ru',
		},
	];

	const linksAdditionalInfo = [
		{
			text: 'Заявление о раскрытии информации',
			href: 'https://drive.google.com/file/d/1_61QdAzJGsTNtaacpinnU_6mklqfs2q-/view?usp=sharing',
		},
		{
			text: 'Антиманипуляция',
			href: 'https://drive.google.com/file/d/12TARM5PqUewUcomioyVy33MLkkUthf_J/view?usp=sharing',
		},
	];

	return (
		<div className='members'>
			<div className='members__tabs'>
				{tabs.map((tab) => (
					<button
						key={tab.id}
						className={`members__tab ${
							activeTab === tab.id ? 'members__tab--active' : ''
						}`}
						onClick={() => setActiveTab(tab.id)}
					>
						{tab.title}
					</button>
				))}
			</div>

			{activeTab === 'members' && (
				<div className='members__content'>
					<div className='members__header'>
						<span className='members__header-item'>Название</span>
						<span className='members__header-item'>Веб-сайт</span>
					</div>
					{members.map((participant, index) => (
						<div key={index} className='members__row'>
							<span className='members__name'>{participant.name}</span>
							<a
								target='_blank'
								href={participant.website}
								className='members__website'
							>
								Веб-сайт
							</a>
						</div>
					))}
				</div>
			)}

			{activeTab === 'legislation' && (
				<div className='legislation'>
					<div className='legislation__tabs'>
						{legislationTabs.map((tab) => (
							<button
								key={tab.id}
								className={`legislation__tab ${
									activeLegislationTab === tab.id
										? 'legislation__tab--active'
										: ''
								}`}
								onClick={() => setActiveLegislationTab(tab.id)}
							>
								{tab.title}
							</button>
						))}
					</div>
					{activeLegislationTab === 'rules' && (
						<ul className='legislation__list'>
							{linksRegulations.map((rule, index) => (
								<li key={index} className='legislation__item'>
									<a href={rule.href} target='_blank'>
										{rule.text}
									</a>
								</li>
							))}
						</ul>
					)}
					{activeLegislationTab === 'laws' && (
						<ul className='legislation__list'>
							{linksLaws.map((law, index) => (
								<li key={index} className='legislation__item'>
									<a href={law.href} target='_blank'>
										{law.text}
									</a>
								</li>
							))}
						</ul>
					)}
				</div>
			)}

			{activeTab === 'additional' && (
				<ul className='legislation__list'>
					{linksAdditionalInfo.map((info, index) => (
						<li key={index} className='legislation__item'>
							<a href={info.href} target='_blank'>
								{info.text}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Members;
