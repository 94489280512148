import React from 'react';
import Listing from '../listing/Listing';
import News from '../news/News';

const Home = () => {
	return (
		<>
			<Listing />
			<News />
		</>
	);
};

export default Home;
