import React, { useState } from 'react';
import './styles.scss';
import NewsImage1 from '../../../images/evde-news.png';
import NewsImage2 from '../../../images/evde-news2.png';
import NewsImage3 from '../../../images/evde-news3.png';
import NewsImage4 from '../../../images/evde-news4.png';

const ExchangeNews = () => {
	const [news, setNews] = useState([
		{
			title:
				'ОАО «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ» Сообщает, что 29 апреля 2023 года состоялось годовое общее собрание акционеров',
			desc: '',
			date: '16-05-2023',
			image: NewsImage1,
			link: '/assets/news/эркинтооГОСА2023.pdf#page=11',
		},

		{
			title:
				'Внеочередное общее собрание акционеров Открытого акционерного общества «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ»',
			desc: '',
			date: '20-10-2023',
			image: NewsImage2,
			link: '/assets/news/Газета эркин тоо.pdf#page=10',
		},

		{
			title:
				'Внеочередное общее собрание акционеров Открытого акционерного общества «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ»',
			desc: '',
			date: '14-11-2023',
			image: NewsImage3,
			link: '/assets/news/эркин тоо газета.pdf#page=14',
		},
		{
			title:
				'ОАО «ЕНВОЙС ВИЖИОН ДИДЖИТАЛ ЭКСЧЕЙНДЖ» Сообщает, что 29 апреля 2024 года состоялось годовое общее собрание акционеров',
			desc: '',
			date: '10-05-2024',
			image: NewsImage4,
			link: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
		},
	]);

	return (
		<>
			<section className='news'>
				<div className='container'>
					<h1 className='news__title'>Новости ЕВДЭ</h1>
					<div className='news__content'>
						{news.reverse().map((newsItem, index) => (
							<div key={index} className='news__col'>
								<a
									href={newsItem.link}
									target='_blank'
									className='news__card'
									rel='noopener noreferrer'
								>
									<div className='news__card-image--block'>
										<img
											src={newsItem.image}
											className='news__card-img'
											alt='news'
										/>
									</div>
									<span className='news__card-date'>{newsItem.date}</span>
									<h3 className='news__card-title'>{newsItem.title}</h3>
								</a>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default ExchangeNews;
