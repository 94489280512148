import React, { useState } from 'react';
import './styles.scss';
import NewsImage1 from '../../../images/royal-news.png';

const CompanyNews = () => {
	const [news, setNews] = useState([
		{
			title: 'Отчет ОАО «Роял» за 2 квартал 2024 года',
			desc: '',
			date: '30-07-2024',
			image: NewsImage1,
			link: '/assets/news/royal.pdf',
		},
	]);

	return (
		<>
			<section className='news'>
				<div className='container'>
					<h1 className='news__title'>Новости компаний</h1>
					<div className='news__content'>
						{news.reverse().map((newsItem, index) => (
							<div key={index} className='news__col'>
								<a
									href={newsItem.link}
									target='_blank'
									className='news__card'
									rel='noopener noreferrer'
								>
									<div className='news__card-image--block'>
										<img
											src={newsItem.image}
											className='news__card-img'
											alt='news'
										/>
									</div>
									<span className='news__card-date'>{newsItem.date}</span>
									<h3 className='news__card-title'>{newsItem.title}</h3>
								</a>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default CompanyNews;
